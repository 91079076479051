import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import configs from './firebaseConfigs';

if (!firebase.apps.length) {
  // @ts-ignore
  firebase.initializeApp(configs[process.env.CUSTOM_ENV] || configs.development);
}

export const auth = firebase.auth();

const storage = firebase.storage();

const storageRef = storage.ref();

export const uploadFile = async (
  path: string,
  file: File | Blob,
  metadata?: firebase.storage.SettableMetadata['customMetadata']
) => {
  try {
    const snapshot = await storageRef.child(path).put(file, { customMetadata: metadata });
    const url = await snapshot.ref.getDownloadURL();

    return { url, path: snapshot.ref.fullPath };
  } catch (err) {
    throw new Error(err);
  }
};

export const getFileUrl = async (path: string) => {
  try {
    const snapshot = storageRef.child(path);
    return snapshot.getDownloadURL();
  } catch (err) {
    throw new Error(err);
  }
};

export const setTrialMetadata = async (path: string, trialStatus: boolean) => {
  const snapshot = storageRef.child(path);

  try {
    await snapshot.updateMetadata({ customMetadata: { isTrial: String(trialStatus) } });
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteFile = async (path: string) => {
  try {
    const snapshot = storageRef.child(path);

    snapshot.delete();
  } catch (err) {
    throw new Error(err);
  }
};
