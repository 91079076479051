const firebaseConfig = {
  development: {
    apiKey: 'AIzaSyAaW3lli-yJBIUD7k0zMykwOck0BNpBjns',
    authDomain: 'supertutortv-courses-dev.firebaseapp.com',
    databaseURL: 'https://supertutortv-courses-dev.firebaseio.com',
    projectId: 'supertutortv-courses-dev',
    storageBucket: 'supertutortv-courses-dev.appspot.com',
    messagingSenderId: '496088846787',
    appId: '1:496088846787:web:84541f9a3f368553b06d76',
  },
  staging: {
    apiKey: 'AIzaSyCARqiza7b3BRRBxitDr7ouoiYpn5pi798',
    authDomain: 'supertutortv-courses-staging.firebaseapp.com',
    databaseURL: 'https://supertutortv-courses-staging.firebaseio.com',
    projectId: 'supertutortv-courses-staging',
    storageBucket: 'supertutortv-courses-staging.appspot.com',
    messagingSenderId: '919264820956',
    appId: '1:919264820956:web:4dcd279d256233c830be19',
  },
  production: {
    apiKey: 'AIzaSyA6fLDHyNd0nJfvV1CC_lSDC_AnByUkW6Q',
    authDomain: 'supertutortv-courses.firebaseapp.com',
    databaseURL: 'https://supertutortv-courses.firebaseio.com',
    projectId: 'supertutortv-courses',
    storageBucket: 'supertutortv-courses.appspot.com',
    messagingSenderId: '688455352206',
    appId: '1:688455352206:web:8d109acec6f6786e11eedf',
  },
};

export default firebaseConfig;
